import { PropsWithChildren } from 'react';
import formatDollarAmount from '@/utils/formatDollarAmount';

interface BillingDetailsCardProps {
    name: string;
    price?: number;
    discount?: any;
}

export default function BillingDetailsCard({
    name,
    price,
    discount,
}: PropsWithChildren<BillingDetailsCardProps>) {
    return (
        <div className="grid grid-cols-2 gap-6">
            <span className="whitespace-nowrap text-base font-medium uppercase">
                {name}
            </span>
            {price! >= 0 && (
                <span className="text-end text-base font-bold">
                    {formatDollarAmount(price!)}
                </span>
            )}
            {discount && (
                <span className="text-end text-base font-bold">
                    {formatDollarAmount(discount)}
                </span>
            )}
        </div>
    );
}

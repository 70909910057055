import {
    setAttemptedCheckout,
    setError as setCheckoutError,
} from '@/store/getStarted/getStartedErrorSlice';
import { setLoading } from '@/store/getStarted/getStartedLoadingSlice';
import { setData } from '@/store/giftCards/giftCardsDataSlice';
import {
    setAttemptedGiftCardsCheckout,
    setError,
} from '@/store/giftCards/giftCardsErrorSlice';
import { setSuccess } from '@/store/giftCards/giftCardsSuccessSlice';

export function privateUnlisten(subscription: any, listeningTo: any) {
    window.Echo.private(`subscriptions.${subscription}`).stopListening(
        listeningTo
    );
}

export function privateListen(
    subscription: any,
    listeningTo: any,
    location: any,
    unlisteningTo: any,
    dispatch?: any
) {
    window.Echo.private(`subscriptions.${subscription}`).listen(
        listeningTo,
        (event: any) => {
            if (event.errors) {
                dispatch(setLoading(false));
                dispatch(setCheckoutError({ error: event.errors }));
                dispatch(setAttemptedCheckout(true));
                return event.errors;
            }
            privateUnlisten(subscription, unlisteningTo);
            window.location.href = location;
            return null;
        }
    );
}

export function promotionPrivateListen(
    subscription: any,
    listeningTo: any,
    unlisteningTo: any,
    dispatch: any,
    queryClient?: any
) {
    window.Echo.private(`subscriptions.${subscription}`).listen(
        listeningTo,
        (event: any) => {
            if (event.errors) {
                dispatch(setLoading(false));
                return event.errors;
            }
            dispatch(setLoading(false));
            privateUnlisten(subscription, unlisteningTo);
            queryClient.invalidateQueries(['singleMenu']);
            return null;
        }
    );
}

export function giftCardsPrivateUnlisten(user: any, listeningTo: any) {
    window.Echo.private(`users.${user}`).stopListening(listeningTo);
}

export function giftCardsPrivateListen(
    user: any,
    listeningTo: any,
    unlisteningTo: any,
    dispatch: any,
    queryClient?: any
) {
    window.Echo.private(`users.${user}`).listen(listeningTo, (event: any) => {
        if (event.errors) {
            dispatch(setLoading(false));
            dispatch(setError({ error: event.errors }));
            dispatch(setAttemptedGiftCardsCheckout(true));
            return event.errors;
        }
        if (event.balance > 0 && queryClient) {
            queryClient.setQueryData(['creditBalance'], {
                balance: event.balance,
            });
        }
        if (event.code && event.value) {
            dispatch(setData({ user, code: event.code, value: event.value }));
        }
        dispatch(setLoading(false));
        dispatch(setSuccess(true));
        if (unlisteningTo !== '') {
            giftCardsPrivateUnlisten(user, unlisteningTo);
        }
        return null;
    });
}

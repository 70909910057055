import { PayloadAction, createSelector, createSlice } from '@reduxjs/toolkit';
import type { RootState } from '../types';

type Step = {
    index: number;
    label: string;
    to?: string;
};

export type GetStartedState = {
    currentStep: Step;
    steps: Step[];
};

const initialState: GetStartedState = {
    currentStep: {
        index: 0,
        label: 'Welcome',
    },
    steps: [
        {
            index: 0,
            label: 'Welcome',
            to: '/get-started',
        },
        {
            index: 1,
            label: 'Your box',
            to: '/get-started/your-box',
        },
        {
            index: 2,
            label: 'Your items',
            to: '/get-started/your-items',
        },
        {
            index: 3,
            label: 'Add-ons',
            to: '/get-started/addons',
        },
        {
            index: 4,
            label: 'Checkout',
        },
    ],
};

export const getStartedSlice = createSlice({
    name: 'get-started',
    initialState,
    reducers: {
        setStep(state, { payload }: PayloadAction<Step>) {
            state.currentStep = payload;
        },
    },
});

export default getStartedSlice.reducer;

export const { setStep } = getStartedSlice.actions;

const selectSelf = (state: RootState) => state.getStarted;

export const selectSteps = createSelector(selectSelf, (s) => s.steps);
export const selectCurrentStep = createSelector(
    selectSelf,
    (s) => s.currentStep
);

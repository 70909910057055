import { PayloadAction, createSelector, createSlice } from '@reduxjs/toolkit';
import { camelizeKeys } from 'humps';
import { useDispatch, useSelector } from 'react-redux';
import type { RootState } from '../types';

export interface UserState {
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    statuses: Record<string, boolean>;
    subscription: Record<string, string>;
    linked: boolean;
    hasPassword: boolean;
    newsletter: boolean;
    sms: boolean;
    ordered: boolean;
}

const initialState = camelizeKeys(
    JSON.parse(
        document.querySelector<HTMLElement>('#root')?.dataset.user || '{}'
    )
) as UserState;

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setUser(_state, action: PayloadAction<UserState>) {
            return action.payload;
        },
    },
});

export default userSlice.reducer;

export const { setUser } = userSlice.actions;

const selectSelf = (state: RootState) => state.user;

const selectFirstName = createSelector(selectSelf, (s) => s.firstName);
const selectLastName = createSelector(selectSelf, (s) => s.lastName);
const selectFullName = createSelector(
    selectFirstName,
    selectLastName,
    (...args) => args.filter(Boolean).join(' ')
);

export function useUser() {
    const dispatch = useDispatch();
    return [
        useSelector(selectSelf),
        (user: UserState) => dispatch(setUser(user)),
    ] as const;
}

export function useFullName() {
    return useSelector(selectFullName);
}
